<template>
  <article v-loading="loading">
    <ContentNav :name="name" :hiddenHeader="true"></ContentNav>
    <section class="contents">
      <div class="list" v-for="(item, index) in list" :key="item.id">
        <div class="list-content" v-html="item.content"></div>
        <!--<AudioBtn :url="item.audioUrl"></AudioBtn>-->
        <div class="translateSwitchBtn" @click="translateSwitch(index)">
          <div>{{item.translateShow ? '收起译文' : '展开译文'}}</div>
          <div class="icon"></div>
        </div>
        <div class="list-translate" v-html="item.translate" v-show="item.translateShow"></div>
        <div class="divider" />
        <div class="testlets-item">
          <div class="testlets-title  mb-20">
            <span class="testlets-title-value">选择题型</span>
          </div>
          <div class="type-list">
            <div v-for="(type) in item.typeList" :key="type.id" :class="['type-item', type.active ? 'active' : '']" @click="changeType(type, item)">
              <div class="type-item-name mb-20">{{ type.name }}</div>
              <div class="type-item-remark">{{ type.remark }}</div>
            </div>
          </div>
        </div>

        <div class="content-footer">
          <span class="estimated-time">预计用时：{{ item.time }}分钟</span>
          <div :class="['content-footer-btn', item.columnShow  ? 'delete' : '']" @click="columnShowSwitch(index)">{{ item.columnShow ? '移除清单' : '加入清单' }}</div>
        </div>
      </div>
    </section>

    <Trolley
        @listState="listState"
    ></Trolley>
  </article>
</template>

<script>
  import ContentNav from '@/components/ContentNav.vue'
  // import AudioBtn from '@/components/AudioBtn.vue'
  import { PracticeModeEnum } from '@/utils/enum.js'
  import Trolley from './components/trolley.vue'

  export default {
    name: 'repeatAfter',
    components: {
      ContentNav,
      // AudioBtn,
      Trolley
    },
    data() {
      return {
        loading: false,
        name: '',
        list: [],
        practiceModeList: [],
        estimatedTime: 0
      }
    },
    created() {
      this.id = this.$route.query.id
      this.name = this.$route.query.name
      this.flag = Number(this.$route.query.flag)
      this.indexModuleName = this.$route.query.indexModuleName
      this.moduleName = this.$route.query.moduleName
      this.moduleId = this.$route.query.moduleId
      this.practiceModeList = PracticeModeEnum[this.flag]
      this.getDetail()
    },
    methods: {
      listState() {
        const {list} = this
        const {selectedWorkList} = this.$store.state
        for (let i = 0, len = list.length; i < len; i += 1) {
          list[i].time = 0
          list[i].columnShow = !selectedWorkList[list[i].id]
          if (!selectedWorkList[list[i].id]) {
            const {typeList} = list[i]
            for (let i = 0, len = typeList.length; i < len; i += 1) {
              typeList[i].active = false
            }
            this.$set(this.list[i], 'columnShow', !list[i].columnShow)
          }
          this.$forceUpdate()
        }
      },
      getDetail() {
        this.loading = true
        const {flag} = this
        let url
        let dataParam = {}
        if (flag === 3) {
          url = this.$urls.findListByUnitId
          dataParam.unitId = this.id
        } else {
          url = this.$urls.repeatAfterSelect
          dataParam.id = this.id
        }
        this.$axios({
          method: 'post',
          url,
          data: dataParam
        }).then((response) => {
          if (response.state === '11') {
            const data = flag === 3 ? response.data : [response.data]
            const {selectedWorkList} = this.$store.state
            for (let i = 0, len = data.length; i < len; i += 1) {
              data[i].content = this.common.evaluat(data[i].content)
              data[i].translate = this.common.evaluat(data[i].translate)
              data[i].typeList = _.cloneDeep(this.practiceModeList)
              data[i].time = 0
              const {id} = data[i]
              if (selectedWorkList[id]) {
                data[i].columnShow = true
                const {typeList} = data[i]
                const {typeList: typelist2} = selectedWorkList[id]
                let time = 0
                for (let k = 0, len = typeList.length; k < len; k += 1) {
                  for (let l = 0, len = typelist2.length; l < len; l += 1) {
                    if ((typeList[k].type === typelist2[l].type) && typelist2[l].active) {
                      typeList[k].active = true
                      time += 5
                      break
                    }
                  }
                }
                data[i].time = time
                break
              }
            }
            this.list = data
          }
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      },
      columnShowSwitch(index) {
        const {list} = this
        const {typeList, item} = list[index]
        const arr = typeList.filter(el => el.active)
        if (!arr.length) {
          this.$alert('请选择练习模式', '提示', {
            confirmButtonText: '确定'
          })
          return
        }
        const {columnShow} = list[index]
        this.$set(this.list[index], 'columnShow', !columnShow)
        if (columnShow) {
          list[index].time = 0
          for (let i = 0, len = typeList.length; i < len; i += 1) {
            typeList[i].active = false
          }
          this.$store.commit('listDel', {id: list[index].id})
          return
        }
        // typeList[0].active = true
        // list[index].time = 0
        this.listSelect(index)
      },
      changeType(type, item) {
        type.active = !type.active
        const arr = item.typeList.filter(el => el.active)
        item.time = arr.length * 5
        this.$forceUpdate()
      },
      typeSelected(index, tIndex) {
        const {list} = this
        const {typeList} = list[index]
        let time = list[index].time || 0
        typeList[tIndex].active = !typeList[tIndex].active
        if (typeList[tIndex].active) {
          time += 5
        } else {
          time -= 5
        }
        list[index].time = time
        this.$set(this.list, index, list[index])
        this.listSelect(index)
      },
      listSelect(index) {
        const {list} = this
        const arr = []
        const {typeList} = list[index]
        const {time} = list[index]
        for (let j = 0, len = typeList.length; j < len; j += 1) {
          if (typeList[j].active) {
            arr.push(typeList[j])
          }
        }
        let {moduleName} = this
        if (list[index].shortName !== this.name) {
          moduleName += `>${this.name}`
        }
        const obj = {
          pId: this.id,
          id: list[index].id,
          name: list[index].shortName || list[index].name,
          flag: this.flag,
          indexModuleName: this.indexModuleName,
          moduleName,
          typeList: arr,
          time,
          moduleId: this.moduleId
        }
        if (arr.length > 0) {
          this.$store.commit('listAdd', obj)
        } else {
          this.$set(this.list[index], 'columnShow', false)
          this.$store.commit('listDel', obj)
        }
      },
      translateSwitch(index) {
        const {list} = this
        const {translateShow} = list[index]
        this.$set(this.list[index], 'translateShow', !translateShow)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .contents {
    height: calc(100vh - 134px);
    overflow-y: auto;
    border-radius: 16px;
    padding: 28px;
    background-color: #FFFFFF;

    .list {
      background-color: #fff;
      //padding: 20px 20px 14px;
      margin-bottom: 10px;
      border-radius: 24px;
      padding: 28px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
      .title {
        @include flex(space-between);
        background-color: #F6F6F6;
        height: 54px;
        padding: 0 28px 0 14px;

        .name {
          font-weight: bold;
        }
      }

      .column {
        @include flex(space-between);
        background-color: #F6F6F6;
        @include font(14px, #999999);
        padding: 0 28px 0 14px;
        overflow: hidden;
        transition: height .3s;

        .type-list {
          @include flex;
          margin-top: 10px;
          margin-bottom: 16px;

          li {
            background-color: #fff;
            height: 28px;
            line-height: 28px;
            padding: 0 20px;
            border: 1px solid transparent;
            border-radius: 4px;
            margin-right: 14px;
            @include font(14px, #666666, center);
            cursor: pointer;

            &:hover {
              color: #333;
            }
          }

          .active {
            background-color: #EAF4FD;
            color: #309AF2;
            border-color: #309AF2;
          }
        }

        .time {
          color: #333;
        }
      }

      .list-content {
        white-space: pre-line;
        font-size: 18px;
        line-height: 25px;
      }

      .translateSwitchBtn {
        @include flex(flex-start);
        @include font(14px, #309AF2);
        margin: 10px 0 14px;
        cursor: pointer;

        .icon {
          @include icon(url("../../../assets/z.png"), 7px, 4.09px);
          margin-left: 2px;
        }
      }

      .list-translate {
        white-space: pre-line;
        font-size: 18px;
        line-height: 25px;
        overflow: hidden;
      }
    }
    .content-footer{
      padding-top: 28px;
      width: 100%;
      //text-align: center;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      .estimated-time{
        color: #999999;
        font-size: 15px;
        line-height: 21px;
        margin-bottom: 6px;
      }
      .content-footer-btn{
        padding: 8px 31px;
        height: 40px;
        color: #FFFFFF;
        font-size: 17px;
        line-height: 24px;
        border-radius: 8px;
        background-color: #1485E3;
        cursor: pointer;
        &.delete{
          background: linear-gradient(90deg, #FF3C30 0%, #FF3C30 100%);
          &:hover{
            background: linear-gradient(90deg, #FF3C30 0%, #FF3C30 100%);
          }
        }

        &:hover{
          background: linear-gradient(90deg, #1485E3 0%, #1485E3 100%);
        }
      }
    }
  }
  .divider{
    margin: 20px 0;
    height: 1px;
    background-color: #F1F1F1;
  }
  .testlets-item{
    .testlets-title{
      @include flex(space-between);
      width: 100%;
      .testlets-title-value{
        color: #333333;
        font-size: 20px;
        line-height: 28px;
        font-weight: bold;
      }
    }
    .type-list{
      @include flex(flex-start);
      flex-wrap: wrap;
      margin: -7px;
      .type-item{
        margin: 7px;
        padding: 20px;
        width: 316px;
        color: #333333;
        font-size: 18px;
        line-height: 24px;
        border-radius: 16px;
        border: 1px solid #E5E5E5;
        background-color: #FFFFFF;
        cursor: pointer;
        &.active{
          font-weight: bold;
          background-color: #EAF4FD;
          border: 1px solid #309AF2;
          .type-item-name{
            color: #309AF2;
          }
          .type-item-remark{
            color: #309AF2;
          }
        }
        .type-item-name{
          color: #333333;
          font-size: 18px;
          line-height: 25px;
        }
        .type-item-remark{
          color: #999999;
          font-size: 15px;
          line-height: 21px;
        }
      }
    }
  }
</style>
